export type Language = {
    code: string
    name: string
}

export const languages: Language[] = [
    {code: 'en', name: $localize`English`},
    {code: 'ca', name: $localize`Catalan`},
    {code: 'de', name: $localize`German`},
    {code: 'es', name: $localize`Spanish`},
    {code: 'fr', name: $localize`French`},
    {code: 'it', name: $localize`Italian`},
    {code: 'hu', name: $localize`Hungarian`},
    {code: 'nl', name: $localize`Dutch`},
    {code: 'no', name: $localize`Norwegian`},
    {code: 'pl', name: $localize`Polish`},
    {code: 'pt', name: $localize`Portuguese`},
    {code: 'ro', name: $localize`Romanian`},
]

export function getLanguage(code: string): Language {
    return languages.find(l => l.code == code) || languages[0]
}
