import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, Inject, ViewChild} from "@angular/core";
import {
    GamePlayerResponse,
    GamePlayState,
    UserBasicResponse,
    UserFavState
} from "../model/responses";
import {AuthedUserService} from "../service/authed-user.service";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UserFavService} from "src/app/service/api/user-fav.service";
import {UserSearchIntent} from "../model/user";
import {MatOptionModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {SearchService} from "../service/api/search.service";
import {GamePlayService} from "../service/api/game-play.service";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {
    MAT_DIALOG_DATA, MatDialog,
    MatDialogActions,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from "@angular/material/dialog";
import {InputDialogComponent} from "../shared/input-dialog.component";
import {AvatarComponent} from "../user/avatar.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {HeaderComponent} from "../shared/header.component";
import {MatSelect} from "@angular/material/select";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
    template: `
        <mat-dialog-content>
            <div class="row space-between align-center">
                <span i18n class="title">Select players</span>
                <button class="small-icon-button title" mat-icon-button (click)="newPlayer()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <form class="log-play-form column fillWidth">
                <div class="row end" style="margin-bottom: 8px" *ngIf="!data.onlyFriends">
                    <mat-slide-toggle [(ngModel)]="teams" [ngModelOptions]="{standalone: true}" i18n>Teams</mat-slide-toggle>
                </div>
                <mat-form-field subscriptSizing="dynamic">
                    <mat-label i18n>Players</mat-label>
                    <mat-chip-grid #chipGrid i18n-aria-label aria-label="Player selection">
                        <mat-chip-row *ngFor="let player of players" (removed)="removePlayer(player)">
                            <cb-avatar matChipAvatar style="margin-right: -4px" [size]="18"
                                       [name]="player.name" [avatarUrl]="player.avatarUrl" [isBot]="player.isBot">
                            </cb-avatar>
                            <span class="player-name">{{ player.name }}</span>
                            <!--<span class="player-username" *ngIf="player.username">{{ '@' + player.username }}</span>-->
                            <button matChipRemove [attr.aria-label]="'remove ' + player">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                    <input #playerInput [formControl]="playerControl"
                           [matChipInputFor]="chipGrid"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           (input)="onInputType($event)"
                           (matChipInputTokenEnd)="createPlayer($event.value)"
                    />
                </mat-form-field>
                <mat-selection-list class="column align-center">
                    <mat-list-option *ngIf="playerControl.value && playerControl.value!.length > 0 &&
                                    playerControl.value!.charAt(0) != '@' && playerControl.value!.charAt(0) != ' '"
                                     [value]="playerControl.value" (selectedChange)="createPlayer(playerControl.value)">
                        <div class="row left align-center" style="padding: 5px">
                            <cb-avatar matChipAvatar [size]="34" style="min-width: 34px"
                                       [name]="playerControl.value!">
                            </cb-avatar>
                            <span class="player-name" style="margin-left: 10px">{{ playerControl.value }}</span>
                            <span class="player-username" i18n>(New player)</span>
                        </div>
                    </mat-list-option>
                    <ng-container *ngFor="let option of filteredUsers">
                        <mat-list-option *ngIf="!teams || !isPlayerSelected(option)"
                                         [value]="option.userId || option.userPlayerId"
                                         [selected]="isPlayerSelected(option)"
                                         (selectedChange)="selectPlayer(option, $event)">
                            <div class="row left align-center" style="padding: 5px">
                                <cb-avatar matChipAvatar [size]="34" style="min-width: 34px"
                                           [name]="option.name" [avatarUrl]="option.avatarUrl" [isBot]="option.isBot">
                                </cb-avatar>
                                <span class="player-name" style="margin-left: 10px">{{ option.name }}</span>
                                <span *ngIf="option.username" class="player-username">&#64;{{ option.username }}</span>
                                <!--<span *ngIf="!option.username" class="player-username" i18n>(Non-user player)</span>-->
                            </div>
                        </mat-list-option>
                        <div *ngIf="teams && isPlayerSelected(option)" class="mat-mdc-list-item mat-mdc-list-option mdc-list-item mat-mdc-list-item-interactive">
                            <div class="row fillWidth space-between align-center">
                                <div class="row left align-center" style="padding: 5px">
                                    <cb-avatar matChipAvatar [size]="34" style="min-width: 34px"
                                               [name]="option.name" [avatarUrl]="option.avatarUrl" [isBot]="option.isBot">
                                    </cb-avatar>
                                    <span class="player-name" style="margin-left: 10px">{{ option.name }}</span>
                                    <span *ngIf="option.username" class="player-username">&#64;{{ option.username }}</span>
                                    <!--<span *ngIf="!option.username" class="player-username" i18n>(Non-user player)</span>-->
                                </div>
                                <mat-form-field appearance="fill" style="min-width: 100px; max-width: 100px" subscriptSizing="dynamic">
                                    <mat-label i18n>Team</mat-label>
                                    <mat-select [(ngModel)]="option.team" [ngModelOptions]="{standalone: true}">
                                        <mat-option *ngFor="let team of teamOptions" [value]="team">{{team || noTeamString}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                </mat-selection-list>
            </form>
            <div class="row fillWidth center align-center" *ngIf="loading">
                <mat-progress-spinner mode="indeterminate" diameter="32"></mat-progress-spinner>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between" style="margin-top: 16px">
            <button mat-button cdkFocusInitial (click)="cancel()" i18n>Cancel</button>
            <button mat-raised-button color="accent" (click)="save()" i18n>Select players</button>
        </div>
    `,
    styles: [`

        .title {
            font-size: 1.2rem;
            font-weight: 700;
            color: white;
            margin-bottom: 16px;
        }

        .section-separation {
            margin-bottom: 16px;
        }

        .log-play-form {

            .mat-form-field {
                width: 100%;
            }

            .log-full-width {
                width: 100%;
            }

            .add-player-field {
                margin-top: 10px;
            }
        }

        .chip {
            width: 18px !important;
            height: 18px !important;
        }

        .player-name {
            font-size: 1rem;
            font-weight: 700;
            white-space: nowrap;
            color: white;
        }

        .player-username {
            margin-left: 6px;
            font-size: 1rem;
            font-weight: 400;
            color: #b1b8be;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule, FormsModule,
        MatFormFieldModule, MatSlideToggleModule, MatInputModule, MatChipsModule, MatIconModule, MatAutocompleteModule, MatOptionModule, MatButtonModule, MatListModule, MatDialogContent, MatDialogTitle, MatDialogActions,
        AvatarComponent, HeaderComponent, MatSelect, MatProgressSpinner,
    ]
})
export class PlayersSelectionDialogComponent {

    separatorKeysCodes: number[] = [ENTER, COMMA];

    playerControl = new FormControl('');

    teams: boolean = false
    teamOptions: (string | undefined)[] = [undefined, 'A', 'B', 'C', 'D']
    noTeamString = $localize`No team`

    selectablePlayers: GamePlayerResponse[] = []

    filteredUsers: GamePlayerResponse[] = []

    players: GamePlayerResponse[] = []

    loading = false

    @ViewChild('playerInput') playerInput: ElementRef<HTMLInputElement>;

    constructor(
        private dialog: MatDialog,
        private searchService: SearchService,
        private userFavService: UserFavService,
        private authedUserService: AuthedUserService,
        private gamePlayService: GamePlayService,
        @Inject(MAT_DIALOG_DATA) public data: {
            players: GamePlayerResponse[],
            onlyFriends: boolean,
        },
        private dialogRef: MatDialogRef<PlayersSelectionDialogComponent>,
    ) {
        this.players = [...data.players]
        if (this.players.some(p => p.team)) {
            this.teams = true
        }
    }

    ngOnInit() {
        this.authedUserService.assertAuthedUser()
        const user = this.authedUserService.getUserData()!
        const userPlayer = this.players.find(p => p.userId == user.id)
        if (this.players.length == 0) {
            this.selectablePlayers = [{
                name: user.name,
                userId: user.id,
                username: user.username,
                avatarUrl: user.avatarUrl,
                team: userPlayer?.team,
                isBot: false,
            }]
        } else {
            this.selectablePlayers = [...this.players]
        }
        this.loading = true
        if (this.data.onlyFriends) {
            this.loadFriends()
            return
        }
        this.gamePlayService.getUserGamePlays(user.username, [GamePlayState.FINISHED]).subscribe(plays => {
            const players = plays.elements
                .flatMap(p => p.players)
            players.forEach(p => {
                if (!this.selectablePlayers.some(u =>
                    (u.userId && u.userId == p.userId) || (!u.userId && !p.userId && u.name == p.name)
                )) {
                    this.selectablePlayers.push(
                        {name: p.name, userId: p.userId, userPlayerId: p.userPlayerId, username: p.username, avatarUrl: p.avatarUrl, isBot: p.isBot}
                    )
                }
            })
            this.performFilter('')

            this.loadFriends()
        })
    }

    loadFriends() {
        this.userFavService.getUserFavs(this.authedUserService.getUserId()!).subscribe(favs => {
            const friends: UserBasicResponse[] = favs.list
                .filter(f => f.state == UserFavState.RECIPROCAL)
                .map(f => { return {...f} })
            this.selectablePlayers.push(...friends
                .filter(f => !this.selectablePlayers.some(p => p.userId == f.id))
                .map(f => {
                    return {name: f.name, userId: f.id, username: f.username, avatarUrl: f.avatarUrl}
                })
            )
            this.performFilter('')
            this.gamePlayService.getUserPlayers().subscribe(users => {
                this.selectablePlayers.push(...users.list
                    .filter(f => !this.selectablePlayers.some(p => p.userPlayerId == f.id))
                    .map(f => {
                        return {name: f.name, userPlayerId: f.id, avatarUrl: f.avatarUrl, isBot: f.isBot}
                    })
                )
                this.performFilter('')
                this.loading = false
            })
        })
    }

    onInputType(event: any) {
        this.performFilter(event.target.value)
    }

    performFilter(filter: string) {
        let filteredBase = this.selectablePlayers
        //console.log(this.selectablePlayers)
        if (filter.length < 1) {
            this.filteredUsers = filteredBase.slice(0, 100)
            return
        }
        const onlyUsers = filter.charAt(0) == '@'
        if (onlyUsers) {
            filter = filter.substring(1)
        }
        filteredBase = filteredBase.filter(f =>
            (!onlyUsers && f.name.toLowerCase().includes(filter.toLowerCase())) ||
            (f.username && f.username.toLowerCase().includes(filter.toLowerCase()))
        ).slice(0, 20)
        this.filteredUsers = filteredBase
        if (this.data.onlyFriends) {
            return
        }
        this.searchService.searchUsers(filter, UserSearchIntent.PLAY).subscribe(users => {
            const filteredUsers = users.list
                .filter(u => !filteredBase.some(f => f.userId == u.id))
                .map(u => {
                    const player = this.players.find(p => p.userId == u.id)
                    return {name: u.name, userId: u.id, username: u.username, avatarUrl: u.avatarUrl, team: player?.team}
                })
            this.filteredUsers = filteredBase.concat(filteredUsers)
        })
    }

    isPlayerSelected(player: GamePlayerResponse) {
        return this.players.some(p => (p.userId && p.userId == player.userId) || (p.userPlayerId && p.userPlayerId == player.userPlayerId))
    }

    selectPlayer(player: GamePlayerResponse, selected: boolean) {
        console.log('Selecting player', player, selected)
        // Trigger only at select
        if (selected && !this.isPlayerSelected(player)) {
            this.players.push(player)
            setTimeout(() => {
                this.playerControl.reset()
                this.playerControl.setValue('')
                this.playerInput.nativeElement.value = ''
            }, 100)
            this.updateData()
        } else if (!selected) {
            this.removePlayer(player)
        }
    }

    newPlayer() {
        this.dialog.open(InputDialogComponent, {
            data: {
                title: $localize`New Player`,
                content: $localize`Enter the name of the new player`,
                inputLabel: $localize`Name`,
                inputType: 'text'
            }
        }).afterClosed().subscribe(name => {
            if (name) {
                this.createPlayer(name)
            }
        })
    }

    creatingPlayer = false

    createPlayer(name: string | null) {
        if (name && !this.creatingPlayer && this.players.every(p => p.name != name)) {
            console.log('Creating player', name)
            const request = {
                name: name
            }
            this.creatingPlayer = true
            this.gamePlayService.createUserPlayer(request).subscribe(userPlayer => {
                const player = {userPlayerId: userPlayer.id, name: userPlayer.name}
                this.selectablePlayers.unshift(player)
                this.players.push(player)
                this.playerControl.reset()
                this.playerControl.setValue('')
                this.playerInput.nativeElement.value = ''
                this.creatingPlayer = false
                this.updateData()
            })
        }
    }

    removePlayer(player: GamePlayerResponse) {
        console.log('Removing player', player)
        this.players = this.players.filter(p => (p.userId && p.userId != player.userId) || (p.userPlayerId && p.userPlayerId != player.userPlayerId))
        this.updateData()
    }

    updateData() {
        this.performFilter('')
    }

    cancel() {
        this.dialogRef.close()
    }

    save() {
        if (!this.teams) {
            this.players.forEach(p => p.team = undefined)
        }
        this.dialogRef.close(this.players)
    }
}
