import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {NavigationService} from "src/app/service/ui/navigation.service";
import {GamePlayerResponse} from "../model/responses";
import {CommonModule} from "@angular/common";
import {AvatarComponent} from "../user/avatar.component";

@Component({
    selector: 'cb-game-play-player-list',
    template: `
        <div class="row center align-center">
            <div class="row center align-center avatars winners" *ngIf="winners.length > 0">
                <div class="avatar-container row center align-center" *ngFor="let player of winners; let i = index"
                     [style.z-index]="winners.length - i"
                     [style.background-color]="player.color || 'var(--background-color)'"
                     [style.padding]="(player.color && circleThickness) ? '1px' : circleThickness + 'px'">
                    <cb-avatar [name]="player.name" [avatarUrl]="player.avatarUrl" [isBot]="player.isBot" [size]="34"></cb-avatar>
                    <img class="crown" src="assets/crown.svg" alt="crown"/>
                </div>
            </div>
            <div class="row center align-center avatars">
                <div class="avatar-container" *ngFor="let player of otherPlayers; let i = index"
                     [style.z-index]="otherPlayers.length - i"
                     [style.background-color]="player.color || 'var(--background-color)'"
                     [style.padding]="(player.color && circleThickness) ? '1px' : circleThickness + 'px'">
                    <cb-avatar [name]="player.name" [avatarUrl]="player.avatarUrl" [size]="34" [isBot]="player.isBot"></cb-avatar>
                </div>
            </div>
            <span class="extra-player-count" *ngIf="extraPlayerCount > 0">+{{ extraPlayerCount }}</span>
        </div>
    `,
    styles: [`
        .winners {
            margin-right: 12px;
        }

        .avatars {
            display: flex;
            margin-top: 8px;
            justify-content: flex-start;

            .avatar-container {
                position: relative;
                margin-right: -12px;

                width: 34px;
                height: 34px;
                border-radius: 50%;
                background-color: var(--background-color);
                z-index: 10;

                .crown {
                    position: absolute;
                    top: -10px;
                    width: 20px;
                    height: 20px;
                    object-fit: cover;
                    z-index: 20;
                }
            }
        }

        .extra-player-count {
            font-size: 1rem;
            font-weight: 700;
            margin-top: 8px;
            margin-left: 18px;
        }
    `],
    standalone: true,
    imports: [CommonModule, AvatarComponent]
})
export class GamePlayPlayerListComponent implements OnInit, OnChanges {

    @Input()
    players: GamePlayerResponse[] = []

    @Input()
    circleThickness: number = 4

    winners: GamePlayerResponse[] = []
    otherPlayers: GamePlayerResponse[] = []
    extraPlayerCount: number

    constructor(
        public navigationService: NavigationService
    ) {
    }

    ngOnInit(): void {
        this.sortPlayers()
    }

    ngOnChanges(): void {
        this.sortPlayers()
    }

    sortPlayers() {
        if (!this.players) {
            this.players = []
        }
        const playersToDisplay = 5
        let players = this.players.sort((a, b) => scorePlayer(b) - scorePlayer(a))
        if (players.length > playersToDisplay) {
            this.extraPlayerCount = players.length - playersToDisplay
            players = players.slice(0, playersToDisplay)
        } else {
            this.extraPlayerCount = 0
        }
        this.winners = players.filter(p => p.isWinner)
        this.otherPlayers = players.filter(p => !p.isWinner)
    }
}

function scorePlayer(player: GamePlayerResponse): number {
    return player.score || -100000000 + (player.isWinner ? 100000000 : 0)
}
